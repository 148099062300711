import React from 'react';
import './navbar.css';
import logo from '../../assets/logo.png';

const Navbar = () => {
  const primaryColor = 'hsl(225, 63%, 24%)';

  return (
    <section className='navBarSection'>
      <header className="header flex">
        <div className="logoDiv">
          <a href="#" className="logo flex">
            <img src={logo} alt="Logo" />
          </a>
        </div>
        <button className='btn'>
          <a href="#">info@apparnafabtech.net +91 9948298693</a>
        </button>
      </header>

      <div style={{ backgroundColor: primaryColor, color: 'white' }}>
        <div className="navBar">
          <ul className="navLists flex">
            <li className="navItem">
              <a href="/" className="navLink">Home</a>
            </li>
            <li className="navItem dropdown">
              <a href="/abdup" className="navLink">AboutUs</a>
              <div className="dropdown-content">
                <a href="/associatesub">Associates</a>
                <a href="/mission">Mission</a>
                <a href="/clients1">Clients</a>
              </div>
            </li>
            <li className="navItem">
              <a href="/services" className="navLink">Services</a>
              <div className="dropdown-content">
                <a href="/transformertank">M S Transformer Tank</a>
                <a href="/dtt">Distribution Transformer Tank</a>
                <a href="/tcore">Transformer Core Frame Parts</a>
                <a href="/eheat">Electric Chambers</a>
                <a href="/cwind">Transformer Coil Winding Machines</a>
                <a href="/tpitch">Transformer Pitch Plates</a>
              </div>
            </li>
            <li className="navItem">
              <a href="/fabrications" className="navLink">Fabrications</a>
            </li>
            <li className="navItem">
              <a href='/careers' className="navLink">Careers</a>
            </li>
            <li className="navItem">
              <a href="/blog" className="navLink">Blog</a>
              <div className="dropdown-content">
                <a href="/quality">Quality</a>
                <a href="/gallery">Gallery</a>
                <a href="/certifications">Certifications</a>
              </div>
            </li>
            <li className="navItem">
              <a href="/contactus" className="navLink">ContactUs</a>
            </li>
          </ul>
        </div>
      </div>
    </section>
  );
}

export default Navbar;
