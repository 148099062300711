import React from 'react';
import './aboutus.css';
import { Link } from 'react-router-dom';
import meet from '../../assets/meet.png'
import aew from '../../assets/aew.png'
import Navbar from '../Navbar/Navbar';
import Footer from '../Footer/Footer';

function AboutUs() {
  
    return (
      <><Navbar/>
      <div className="container">
      <div className="piccc">
        <img src={meet}/>
        <h1>Innovation, Quality with Integrity</h1>
      </div>
      <div className="about-us-container">
        <div className="description-section">
          
          <h2>Who we are</h2>
          <p>
            Apparna Fab-Tech Pvt Ltd is one of the foremost Manufacturer and Supplier
            of extremely constructive collection of Coil Winding Machines, Transformer
            Oil Tanks, Transformer Core Frames and Heating Chambers. Apparna has been
            at the forefront of the transformer tank fabrication industry, delivering
            top-notch services and products that exceed client expectations.
          </p>
        </div>
        <div className="associates-section">
  
          <button>
          <a href="/associatesub">ASSOCIATES+</a>
          </button>
          <div className="associate-logo">
            <img src={aew} alt="AEW Logo" />
            <p>Apparna Engineering Works</p>
          </div>
        </div>
      </div></div>
      <Footer/></>
    );
  }
  
  export default AboutUs;

 
  